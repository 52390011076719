
import {
  defineComponent, reactive, ref, watch,
} from 'vue';
import { DataNode } from 'ant-design-vue/lib/tree';
import { Modal, TreeProps } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { IEstruturaDREGrupo, IEstruturaDREGrupoCenarioFiscal, IEstruturaDREGrupoOrigemDado } from '@/models/Relatorios/Financeiro/DRE/IEstruturaDRE';
import ServicoRelatorioDRE from '@/servicos/Relatorios/ServicoRelatorioDRE';
import SelecionarTipoGrupoResultado from '@/components/Relatorios/Financeiro/SelecionarTipoGrupoResultado.vue';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarPlanoConta.vue';
import SelecionarCenarioFiscal from '@/components/Cadastros/Tributacoes/CenariosFiscais/SelecionarCenarioFiscal.vue';
import { IPlanoContaCategoria } from '@/models/Entidades/Cadastros/PlanosContas/IPlanoContaCategoria';
import ServicoPlanoContas from '@/servicos/Cadastros/PlanosContas/ServicoPlanoContas';
import { ETipoGrupoDRE } from '@/models/Enumeradores/Relatorios/ETipoGrupoDRE';
import { ETipoOrigemDadoDRE } from '@/models/Enumeradores/Relatorios/ETipoOrigemDadoDRE';
import { EVariavelSistemaDRE } from '@/models/Enumeradores/Relatorios/EVariavelSistemaDRE';
import SelecionarGrupoDRE from '@/components/Relatorios/Financeiro/SelecionarGrupoDRE.vue';

export default defineComponent({
  name: 'GrupoEstruturaDREModal',
  props: {
    estrutura: {
      type: Number,
      required: true,
    },
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    Card,
    SelecionarTipoGrupoResultado,
    SelecionarPlanoConta,
    SelecionarCenarioFiscal,
    SelecionarGrupoDRE,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, apresentarMensagemSucesso, apresentarMensagemAlerta, apresentarMensagemErro,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso, sincronizarRegistro,
    } = useModalBase(props, emit);
    const servicoRelatorioDRE = new ServicoRelatorioDRE();
    const servicoPlanoContas = new ServicoPlanoContas();
    const treeData = ref<TreeProps['treeData']>([]);
    telaBase.identificadorRecurso = 'RELATORIO_DRE';
    telaBase.identificadorPermissao = 'REL_DRE';

    const state = reactive({
      grupo: {} as IEstruturaDREGrupo,
      utilizarTotalVendas: false,
      utilizarTotalServicos: false,
      utilizarTotalDevolucoes: false,
      utilizarCmv: false,
      gruposUtilizados: [] as number[],
      cenariosFiscais: [] as number[],
      categoriasSelecionadas: [] as number[],
      categoriasExpandidas: [] as number[],
      categorias: [] as IPlanoContaCategoria[],
      buscaRapidaCategorias: '',
    });

    function iniciarTela() {
      const grupo = {} as IEstruturaDREGrupo;
      grupo.codigoEstruturaDRE = props.estrutura;
      grupo.codigoPlanoConta = 0;
      grupo.tipo = ETipoGrupoDRE.Receitas;
      grupo.origens = [] as IEstruturaDREGrupoOrigemDado[];
      grupo.cenariosFiscais = [] as IEstruturaDREGrupoCenarioFiscal[];
      state.grupo = grupo;
      state.utilizarTotalVendas = false;
      state.utilizarTotalServicos = false;
      state.utilizarTotalDevolucoes = false;
      state.utilizarCmv = false;
      state.cenariosFiscais = [];
      state.categoriasSelecionadas = [] as number[];
      state.categoriasExpandidas = [] as number[];
      state.categorias = [];
      state.gruposUtilizados = [];
      state.buscaRapidaCategorias = '';
    }

    function obterCategoriasUtilizadas() {
      state.categoriasSelecionadas.forEach((c) => {
        const origemCategoria = {} as IEstruturaDREGrupoOrigemDado;
        origemCategoria.codigoEstruturaDREGrupo = state.grupo.codigo;
        origemCategoria.codigoPlanoContaCategoria = c;
        origemCategoria.tipoOrigem = ETipoOrigemDadoDRE.CategoriaPlanoContas;
        state.grupo.origens.push(origemCategoria);
      });
    }

    function obterVariaveisSistemaUtilizadas() {
      if (state.utilizarTotalVendas) {
        const origemTotalVendas = {} as IEstruturaDREGrupoOrigemDado;
        origemTotalVendas.codigoEstruturaDREGrupo = state.grupo.codigo;
        origemTotalVendas.tipoOrigem = ETipoOrigemDadoDRE.VariavelSistema;
        origemTotalVendas.variavelSistema = EVariavelSistemaDRE.TotalVendas;
        state.grupo.origens.push(origemTotalVendas);
      }

      if (state.utilizarTotalServicos) {
        const origemTotalServicos = {} as IEstruturaDREGrupoOrigemDado;
        origemTotalServicos.codigoEstruturaDREGrupo = state.grupo.codigo;
        origemTotalServicos.tipoOrigem = ETipoOrigemDadoDRE.VariavelSistema;
        origemTotalServicos.variavelSistema = EVariavelSistemaDRE.TotalServico;
        state.grupo.origens.push(origemTotalServicos);
      }

      if (state.utilizarTotalDevolucoes) {
        const origemTotalDevolucoes = {} as IEstruturaDREGrupoOrigemDado;
        origemTotalDevolucoes.codigoEstruturaDREGrupo = state.grupo.codigo;
        origemTotalDevolucoes.tipoOrigem = ETipoOrigemDadoDRE.VariavelSistema;
        origemTotalDevolucoes.variavelSistema = EVariavelSistemaDRE.TotalDevolucoesVenda;
        state.grupo.origens.push(origemTotalDevolucoes);
      }

      if (state.utilizarCmv) {
        const origemCmv = {} as IEstruturaDREGrupoOrigemDado;
        origemCmv.codigoEstruturaDREGrupo = state.grupo.codigo;
        origemCmv.tipoOrigem = ETipoOrigemDadoDRE.VariavelSistema;
        origemCmv.variavelSistema = EVariavelSistemaDRE.CMV;
        state.grupo.origens.push(origemCmv);
      }
    }

    function obterGruposUtilizados() {
      state.gruposUtilizados.forEach((g) => {
        const origemGrupo = {} as IEstruturaDREGrupoOrigemDado;
        origemGrupo.codigoEstruturaDREGrupo = state.grupo.codigo;
        origemGrupo.tipoOrigem = ETipoOrigemDadoDRE.GrupoDRE;
        origemGrupo.codigoEstruturaDREGrupoUtilizado = g;
        state.grupo.origens.push(origemGrupo);
      });
    }

    function obterGrupo() {
      state.grupo.codigoEstruturaDRE = props.estrutura;
      state.grupo.origens = [] as IEstruturaDREGrupoOrigemDado[];
      if (state.grupo.tipo === ETipoGrupoDRE.Totalizador) {
        obterGruposUtilizados();
      } else {
        obterCategoriasUtilizadas();
      }
      obterVariaveisSistemaUtilizadas();

      state.grupo.cenariosFiscais = [] as IEstruturaDREGrupoCenarioFiscal[];
      state.cenariosFiscais.forEach((c) => {
        const cenario = {} as IEstruturaDREGrupoCenarioFiscal;
        cenario.codigoCenarioFiscal = c;
        cenario.codigoEstruturaDREGrupo = state.grupo.codigo;
        state.grupo.cenariosFiscais.push(cenario);
      });
    }

    function verificarCampos() {
      if (state.grupo.descricao === '') {
        apresentarMensagemAlerta('A descrição deve ser informada!');
        return false;
      }
      if (state.grupo.origens.length === 0) {
        apresentarMensagemAlerta('O grupo deve ter ao menos uma origem de resultado definida!');
        return false;
      }

      if ((state.utilizarTotalVendas || state.utilizarTotalServicos || state.utilizarTotalDevolucoes || state.utilizarCmv) && state.cenariosFiscais.length === 0) {
        apresentarMensagemAlerta('Deve ser definido ao menos um cenário fiscal!');
        return false;
      }
      return true;
    }

    async function salvar() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      obterGrupo();
      const validacao = verificarCampos();
      if (!validacao) return;
      apresentarBarraProgresso();
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoRelatorioDRE.incluirGrupo(state.grupo, props.operacao.empresaSelecionada);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoRelatorioDRE.alterarGrupo(state.grupo, props.operacao.empresaSelecionada);
      }
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso('Grupo criado com sucesso!');
        modalBase.computedVisivel = false;
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    function montarArvore(categorias:IPlanoContaCategoria[], codigoCategoriaPai: number | null) {
      const treeDataNova: TreeProps['treeData'] = [];
      categorias
        .filter((c) => c.codigoPlanoContaCategoriaPai === codigoCategoriaPai)
        .forEach((d) => {
          const estoqueNode:DataNode = {} as DataNode;
          estoqueNode.key = d.codigo;
          estoqueNode.title = `${d.numero} - ${d.nome}`;
          estoqueNode.children = [];
          estoqueNode.children = montarArvore(categorias, d.codigo);
          return treeDataNova.push(estoqueNode);
        });
      return treeDataNova;
    }

    async function obterCategorias() {
      state.categorias = await servicoPlanoContas.obterCategorias(state.grupo.codigoPlanoConta, props.operacao.empresaSelecionada, '');
      const treeDataNova: TreeProps['treeData'] = montarArvore(state.categorias, null);
      treeData.value = treeDataNova;
    }

    async function preencherGrupo() {
      state.grupo = await servicoRelatorioDRE.obterGrupo(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
      await obterCategorias();

      state.grupo.origens.forEach((o) => {
        if (o.tipoOrigem === ETipoOrigemDadoDRE.GrupoDRE) {
          if (o.codigoEstruturaDREGrupoUtilizado !== null) {
            state.gruposUtilizados.push(o.codigoEstruturaDREGrupoUtilizado);
          }
        } else if (o.tipoOrigem === ETipoOrigemDadoDRE.CategoriaPlanoContas) {
          if (o.codigoPlanoContaCategoria !== null) {
            state.categoriasSelecionadas.push(o.codigoPlanoContaCategoria);
          }
        } else {
          state.utilizarTotalVendas = o.variavelSistema === EVariavelSistemaDRE.TotalVendas;
          state.utilizarTotalServicos = o.variavelSistema === EVariavelSistemaDRE.TotalServico;
          state.utilizarTotalDevolucoes = o.variavelSistema === EVariavelSistemaDRE.TotalDevolucoesVenda;
          state.utilizarCmv = o.variavelSistema === EVariavelSistemaDRE.CMV;
        }
      });

      state.grupo.cenariosFiscais.forEach((c) => {
        state.cenariosFiscais.push(c.codigoCenarioFiscal);
      });
    }

    async function excluir() {
      Modal.confirm({
        title: 'Você realmente deseja remover o grupo?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoRelatorioDRE.excluirGrupo(state.grupo.codigo, props.operacao.empresaSelecionada);
          if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
            apresentarMensagemSucesso('Grupo excluído com sucesso!');
            sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
            modalBase.computedVisivel = false;
          } else {
            apresentarMensagemErro(retorno.mensagem);
          }
        },
      });
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      iniciarTela();
      if (modalBase.computedVisivel) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          await preencherGrupo();
        }
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      state,
      modalBase,
      treeData,
      ETipoGrupoDRE,
      apresentarRetornoRequisicao,
      obterCategorias,
      salvar,
      excluir,
    };
  },
});
