import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';

export default class UtilitarioData {
  public static formatoDataDiaMesAno = 'DD/MM/YYYY';

  public static formatoDataDiaMesAnoAbreviado = 'DD/MM/YY';

  public static formatoDataDiaMesAnoHoraMinutoSegundo = 'DD/MM/YYYY HH:mm:ss';

  public static formatoDataDiaMesAnoHoraMinuto = 'DD/MM/YYYY HH:mm';

  public static formatoHoraMinutoSegundo = 'HH:mm:ss';

  public static formatoHoraMinuto = 'HH:mm';

  static validaDataPreenchida(valor: any):boolean {
    if (valor === undefined || valor === null || valor === '') {
      return false;
    }
    if (valor === '0001-01-01T00:00:00') { return false; }

    return true;
  }

  static converterDataParaDayJs(valor: any):any {
    return dayjs(valor);
  }

  static aplicaFormatoDataHoraMinutoSegundo(valor: any):any {
    return dayjs(valor).format(this.formatoDataDiaMesAnoHoraMinutoSegundo);
  }

  static aplicaFormatoDataHoraMinuto(valor: any):any {
    return dayjs(valor).format(this.formatoDataDiaMesAnoHoraMinuto);
  }

  static aplicaFormatoData(valor: any):any {
    return dayjs(valor).format(this.formatoDataDiaMesAno);
  }

  static aplicaFormatoDataAnoAbreviado(valor: any):any {
    return dayjs(valor).format(this.formatoDataDiaMesAnoAbreviado);
  }

  static aplicaFormatoPersonalizadoData(valor: any, formato: string):any {
    return dayjs(valor).format(formato);
  }

  static aplicaFormatoDataHora(data: any):string {
    return dayjs(data).format('HH:00');
  }

  static verificaDiferencaDiasEntreDatas(primeiraData: any, segundaData:any):number {
    try {
      const primeiraDataDayJs = dayjs(primeiraData);
      const segundaDataDayJs = dayjs(segundaData);

      return segundaDataDayJs.diff(primeiraDataDayJs, 'day');
    } catch (error) {
      return 0;
    }
  }

  static verificaDataMaisRecente(primeiraData: any, segundaData:any):boolean {
    try {
      const primeiraDataDayJs = dayjs(primeiraData);
      const segundaDataDayJs = dayjs(segundaData);
      return primeiraDataDayJs.isAfter(segundaDataDayJs);
    } catch (error) {
      return false;
    }
  }

  static verificarDiaSemana(data: any): boolean {
    const dataJs = dayjs(data);
    const diaSemana = this.obterDiaDaSemanaAbreviado(dataJs);
    return diaSemana !== 'Dom' && diaSemana !== 'Sáb';
  }

  static adicionarMinutosNaData(data: string, quantidadeMinutos: number):string {
    return dayjs(data).add(quantidadeMinutos, 'minute').format('YYYY-MM-DDTHH:mm:ss');
  }

  static diminuirMinutosNaData(data: string, quantidadeMinutos: number):string {
    return dayjs(data).subtract(quantidadeMinutos, 'minute').format('YYYY-MM-DDTHH:mm:ss');
  }

  static adicionarHorasNaData(data: string, quantidadeHoras: number):string {
    return dayjs(data).add(quantidadeHoras, 'hour').format('YYYY-MM-DDTHH:mm:ss');
  }

  static diminuirHorasNaData(data: string, quantidadeHoras: number):string {
    return dayjs(data).subtract(quantidadeHoras, 'hour').format('YYYY-MM-DDTHH:mm:ss');
  }

  static adicionarDiaNaData(data: string, quantidadeDias: number):string {
    return dayjs(data).add(quantidadeDias, 'day').format('YYYY-MM-DD');
  }

  static diminuirDiaNaData(data: string, quantidadeDias: number):string {
    return dayjs(data).subtract(quantidadeDias, 'day').format('YYYY-MM-DD');
  }

  static adicionarMesNaData(data: string, quantidadeMeses: number):string {
    return dayjs(data).add(quantidadeMeses, 'month').format('YYYY-MM-DD');
  }

  static diminuirMesNaData(data: string, quantidadeMeses: number):string {
    return dayjs(data).subtract(quantidadeMeses, 'month').format('YYYY-MM-DD');
  }

  static adicionarDiaNaDataHoraMinutoSegundo(data: string, quantidadeDias: number):string {
    return dayjs(data).add(quantidadeDias, 'day').format('YYYY-MM-DDTHH:mm:ss');
  }

  static diminuirDiaNaDataHoraMinutoSegundo(data: string, quantidadeDias: number):string {
    return dayjs(data).subtract(quantidadeDias, 'day').format('YYYY-MM-DDTHH:mm:ss');
  }

  static adicionarMesNaDataHoraMinutoSegundo(data: string, quantidadeMeses: number):string {
    return dayjs(data).add(quantidadeMeses, 'month').format('YYYY-MM-DDTHH:mm:ss');
  }

  static diminuirMesNaDataHoraMinutoSegundo(data: string, quantidadeMeses: number):string {
    return dayjs(data).subtract(quantidadeMeses, 'month').format('YYYY-MM-DDTHH:mm:ss');
  }

  static adicionarDiaNaDataDayJs(data: Dayjs, quantidadeDias: number):Dayjs {
    return dayjs(data).add(quantidadeDias, 'day');
  }

  static diminuirDiaNaDataDayJs(data: Dayjs, quantidadeDias: number):Dayjs {
    return dayjs(data).subtract(quantidadeDias, 'day');
  }

  static adicionarSemanaNaDataDayJs(data: Dayjs, quantidadeSemanas: number):Dayjs {
    return dayjs(data).add(quantidadeSemanas, 'week');
  }

  static diminuirSemanaNaDataDayJs(data: Dayjs, quantidadeSemanas: number):Dayjs {
    return dayjs(data).subtract(quantidadeSemanas, 'week');
  }

  static adicionarMesNaDataDayJs(data: Dayjs, quantidadeMeses: number):Dayjs {
    return dayjs(data).add(quantidadeMeses, 'month');
  }

  static diminuirMesNaDataDayJs(data: Dayjs, quantidadeMeses: number):Dayjs {
    return dayjs(data).subtract(quantidadeMeses, 'month');
  }

  static adicionarAnoNaDataDayJs(data: Dayjs, quantidadeAnos: number):Dayjs {
    return dayjs(data).add(quantidadeAnos, 'year');
  }

  static diminuirAnoNaDataDayJs(data: Dayjs, quantidadeAnos: number):Dayjs {
    return dayjs(data).subtract(quantidadeAnos, 'year');
  }

  static criarDataDayJs(ano: number, mes:number, dia:number):Dayjs {
    return dayjs(new Date(ano, mes, dia));
  }

  static criarDataHoraMinutoSegundoInicial():string {
    return '0001-01-01T00:00:00';
  }

  static obterDataAtualDayJs():Dayjs {
    return dayjs();
  }

  static obterDataAtual():string {
    return dayjs().format('YYYY-MM-DDTHH:mm:ss');
  }

  static obterPrimeiroDiaMes(data:string):string {
    return this.converterDataJson(this.definePrimeiroDiaMesNaDataDayJs(this.converterDataParaDayJs(data)));
  }

  static obterPrimeiroDiaMesAtual():string {
    return this.converterDataJson(this.definePrimeiroDiaMesNaDataDayJs(this.obterDataAtualDayJs()));
  }

  static obterPrimeiroDiaAno(data: string):string {
    return this.converterDataJson(this.definePrimeiroDiaAnoNaDataDayJs(this.converterDataParaDayJs(data)));
  }

  static obterUltimoDiaMesAtual():string {
    return this.converterDataJson(this.defineUltimoDiaMesNaDataDayJs(this.obterDataAtualDayJs()));
  }

  static obterUltimoDiaAno(data:string):string {
    return this.converterDataJson(this.defineUltimoDiaAnoNaDataDayJs(this.converterDataParaDayJs(data)));
  }

  static obterUltimoDiaMes(data:string):string {
    return this.converterDataJson(this.defineUltimoDiaMesNaDataDayJs(this.converterDataParaDayJs(data)));
  }

  static obterQuantidadeDiasMesDayJs(data: Dayjs):number {
    return data.daysInMonth();
  }

  static defineDiaNaDataDayJs(data: Dayjs, dia:number):Dayjs {
    return dayjs(data).date(dia);
  }

  static definePrimeiroDiaMesNaDataDayJs(data: Dayjs):Dayjs {
    return dayjs(data).date(1);
  }

  static definePrimeiroDiaAnoNaDataDayJs(data: Dayjs):Dayjs {
    const dataJs = dayjs(data).set('date', 1).set('month', 0);
    return dataJs;
  }

  static defineUltimoDiaMesNaDataDayJs(data: Dayjs):Dayjs {
    return dayjs(data).date(data.daysInMonth());
  }

  static defineUltimoDiaAnoNaDataDayJs(data: Dayjs):Dayjs {
    const dataJs = dayjs(data).set('month', 11).set('date', 31);
    return dataJs;
  }

  static obterDiaData(data: any):any {
    return dayjs(data).format('DD');
  }

  static obterDataComDiaSemana(data: any, dia: number):any {
    return dayjs(data).day(dia);
  }

  static obterDiaDaSemana(data: any):any {
    return dayjs(data).locale('pt-br').format('dddd');
  }

  static obterSemanaMes(data: any):any {
    const dia = Number(dayjs(data).format('D'));

    if (dia >= 1 && dia <= 7) {
      return '1ª Semana';
    }

    if (dia >= 8 && dia <= 14) {
      return '2ª Semana';
    }

    if (dia >= 15 && dia <= 21) {
      return '3ª Semana';
    }

    if (dia >= 22 && dia <= 28) {
      return '4ª Semana';
    }

    if (dia >= 29 && dia <= 31) {
      return '5ª Semana';
    }

    return '';
  }

  static obterMesAno(data: any):any {
    return dayjs(data).locale('pt-br').format('MMMM/YYYY');
  }

  static obterAnoData(data: any):number {
    return Number(dayjs(data).format('YYYY'));
  }

  static obterMesData(data: any):string {
    return dayjs(data).format('MM');
  }

  static obterMesInteiroData(data: any):number {
    return Number(dayjs(data).format('M'));
  }

  static obterDiaDaSemanaAbreviado(data: any):any {
    return dayjs(data).locale('pt-br').format('ddd');
  }

  static obterDiaMesAbreviado(data: any):any {
    return dayjs(data).locale('pt-br').format('DD MMM');
  }

  static converterDataJson(data: any):string {
    return dayjs(data).format('YYYY-MM-DD');
  }

  static converterDataHoraMinutoSegundoParaDayJs(data: any):Dayjs {
    return dayjs(data, 'YYYY-MM-DDTHH:mm:ss');
  }

  static converterDataHoraMinutoParaDayJs(data: any):Dayjs {
    return dayjs(data, 'YYYY-MM-DDTHH:mm');
  }

  static converterDataHoraMinutoSegundoJson(data: any):string {
    return dayjs(data).format('YYYY-MM-DDTHH:mm:ss');
  }

  static converterHoraMinutoSegundoParaDayJs(data: any):Dayjs {
    return dayjs(data, 'HH:mm:ss');
  }

  static converterHoraMinutoParaDayJs(data: any):Dayjs {
    return dayjs(data, 'HH:mm');
  }

  static converterHoraMinutoSegundoJson(hora: any):string {
    return dayjs(hora).format('HH:mm:ss');
  }

  static converterHoraMinutoJson(hora: any):string {
    return dayjs(hora).format('HH:mm');
  }

  static obterNomeMes(mes: number):string {
    switch (mes) {
      case 1:
        return 'Janeiro';
      case 2:
        return 'Fevereiro';
      case 3:
        return 'Março';
      case 4:
        return 'Abril';
      case 5:
        return 'Maio';
      case 6:
        return 'Junho';
      case 7:
        return 'Julho';
      case 8:
        return 'Agosto';
      case 9:
        return 'Setembro';
      case 10:
        return 'Outubro';
      case 11:
        return 'Novembro';
      case 12:
        return 'Dezembro';
      default:
        return 'Outro';
    }
  }
}
