
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ETipoConta } from '@/models/Enumeradores/Cadastros/Financeiro/ETipoConta';
import { IEstruturaDRE, IEstruturaDREEmpresa } from '@/models/Relatorios/Financeiro/DRE/IEstruturaDRE';
import ServicoRelatorioDRE from '@/servicos/Relatorios/ServicoRelatorioDRE';
import SelecionarEstruturaDRE from '@/components/Relatorios/Financeiro/SelecionarEstruturaDRE.vue';

export default defineComponent({
  name: 'EstruturaDREModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    SelecionarEstruturaDRE,
  },
  emits: ['update:operacao', 'update:visivel', 'registroAtualizado'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, verificaConceitoParaApresentarEmpresas,
      preencherPermissoesDados, filtrarPermissaoDadosUsuario, apresentarMensagemSucesso, apresentarMensagemAlerta,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);
    const servicoRelatorioDRE = new ServicoRelatorioDRE();

    telaBase.identificadorRecurso = 'RELATORIO_DRE';
    telaBase.identificadorPermissao = 'REL_DRE';

    const state = reactive({
      estrutura: {} as IEstruturaDRE,
      estruturaCopiada: 0,
    });

    function iniciarTela() {
      const estrutura = {} as IEstruturaDRE;
      estrutura.empresas = [];
      estrutura.contas = [];
      estrutura.centrosCusto = [];
      estrutura.grupos = [];
      state.estrutura = estrutura;
    }

    async function salvar() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      if (state.estrutura.descricao === '') {
        apresentarMensagemAlerta('A descrição deve ser informada!');
        return;
      }
      state.estrutura.empresas = [];
      const empresaEstrutura = {} as IEstruturaDREEmpresa;
      empresaEstrutura.codigoEmpresa = props.operacao.empresaSelecionada;
      empresaEstrutura.codigoEstruturaDRE = props.operacao.codigoRegistro;
      state.estrutura.empresas.push(empresaEstrutura);
      apresentarBarraProgresso();
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoRelatorioDRE.incluirEstrutura(state.estrutura);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoRelatorioDRE.alterarEstrutura(state.estrutura);
      }
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          emit('registroAtualizado', retorno.codigoRegistro);
        } else {
          emit('registroAtualizado', props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso('Estrutura salva com sucesso!');
        modalBase.computedVisivel = false;
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      iniciarTela();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, false);
        verificaConceitoParaApresentarEmpresas();
        telaBase.permissaoDados = await filtrarPermissaoDadosUsuario(props.operacao.empresaSelecionada);

        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          state.estrutura = await servicoRelatorioDRE.obterEstrutura(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
          telaBase.empresasSelecionadas = [];
        }
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      state,
      modalBase,
      EPermissaoDados,
      ETipoConta,
      UtilitarioGeral,
      apresentarRetornoRequisicao,
      iniciarTela,
      salvar,
    };
  },
});
