
import { defineComponent, onBeforeMount, reactive } from 'vue';
import { Modal } from 'ant-design-vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import Icone from '@/core/components/Icone.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import storeSistema from '@/store/storeSistema';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import SelecionarEstruturaDRE from '@/components/Relatorios/Financeiro/SelecionarEstruturaDRE.vue';
import { IEstruturaDRE } from '@/models/Relatorios/Financeiro/DRE/IEstruturaDRE';
import SelecionarSazonalidade from '@/core/components/Tela/SelecionarSazonalidade.vue';
import SelecionarGenerico from '@/core/components/Tela/SelecionarGenerico.vue';
import SelecionarConta from '@/components/Cadastros/Financeiro/SelecionarConta.vue';
import SelecionarCentroCusto from '@/components/Cadastros/PlanosContas/SelecionarCentroCusto.vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import EstruturaDREModal from './EstruturaDREModal.vue';
import GrupoEstruturaDREModal from './GrupoEstruturaDREModal.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { ESazonalidade } from '@/core/models/Enumeradores/ESazonalidade';
import ServicoRelatorioDRE from '@/servicos/Relatorios/ServicoRelatorioDRE';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IParametrosDRE } from '@/models/Relatorios/Financeiro/DRE/IParametrosDRE';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import GrupoResultadoDRE from '@/components/Relatorios/Financeiro/GrupoResultadoDRE.vue';
import { ECustomRenderRow, IColumn } from '@/core/models/AntDesign/IColumn';

export default defineComponent({
  name: 'RelatorioDRE',
  components: {
    Icone,
    TituloPadrao,
    PreviewPdf,
    SelecionarEstruturaDRE,
    SelecionarSazonalidade,
    SelecionarGenerico,
    SelecionarConta,
    SelecionarCentroCusto,
    EstruturaDREModal,
    GrupoEstruturaDREModal,
    MensagemSemDados,
    GrupoResultadoDRE,
  },
  setup(props) {
    const {
      telaBase, obterPermissoes, preencherEmpresasDisponiveis, verificaConceitoParaApresentarEmpresas,
      defineEmpresasSelecionadasComPermissao, filtrarPermissaoDadosUsuarioMultiEmpresas,
      adicionarAtalho, removerAtalho, preencherDadosRota, apresentarMensagemErro,
    } = useTelaBase();
    telaBase.apresentarEmpresas = false;
    const servicoRelatorioDRE = new ServicoRelatorioDRE();

    const state = reactive({
      codigoEmpresa: 0,
      codigoEstrutura: 0,
      codigoGrupo: 0,
      tipoPeriodo: 3,
      colunasGrade: [] as IColumn[],
      ano: 2025,
      periodos: [] as IOption[],
      periodosSelecionados: [] as number[],
      contas: [] as number[],
      centrosCusto: [] as number[],
      estrutura: {} as IEstruturaDRE,
      resultado: [] as any[],
      arquivosPdf: [] as IArquivoPdf[],
      operacaoLancamento: {} as ITelaOperacao,
      exibirEstrutura: false,
      exibirGrupo: false,
    });

    function atualizarPeriodos() {
      const anoAtual = UtilitarioData.obterAnoData(UtilitarioData.obterDataAtual());
      state.periodos = [] as IOption[];
      state.periodosSelecionados = [] as number[];

      switch (state.tipoPeriodo) {
        case ESazonalidade.mensal:
          state.periodos.push({ label: 'Jan', value: 1 });
          state.periodos.push({ label: 'Fev', value: 2 });
          state.periodos.push({ label: 'Mar', value: 3 });
          state.periodos.push({ label: 'Abr', value: 4 });
          state.periodos.push({ label: 'Mai', value: 5 });
          state.periodos.push({ label: 'Jun', value: 6 });
          state.periodos.push({ label: 'Jul', value: 7 });
          state.periodos.push({ label: 'Ago', value: 8 });
          state.periodos.push({ label: 'Set', value: 9 });
          state.periodos.push({ label: 'Out', value: 10 });
          state.periodos.push({ label: 'Nov', value: 11 });
          state.periodos.push({ label: 'Dez', value: 12 });
          break;
        case ESazonalidade.trimestral:
          state.periodos.push({ label: '1º Trimestre', value: 1 });
          state.periodos.push({ label: '2º Trimestre', value: 2 });
          state.periodos.push({ label: '3º Trimestre', value: 3 });
          state.periodos.push({ label: '4º Trimestre', value: 4 });
          break;
        case ESazonalidade.semestral:
          state.periodos.push({ label: '1º Semestre', value: 1 });
          state.periodos.push({ label: '2º Semestre', value: 2 });
          break;
        default:
          for (let i = (anoAtual - 10); i <= (anoAtual + 10); i += 1) {
            state.periodos.push({ label: String(i), value: 1 });
          }
          break;
      }
    }

    function limparTela() {
      state.codigoEmpresa = storeSistema.getters.codigoEmpresaOperacao();
      state.ano = UtilitarioData.obterAnoData(UtilitarioData.obterDataAtual());
      atualizarPeriodos();
      for (let i = 1; i <= 12; i += 1) {
        state.periodosSelecionados.push(i);
      }
    }

    async function atualizarSelecaoEmpresas() {
      telaBase.empresasSelecionadas = [] as number[];
      telaBase.empresasSelecionadas.push(state.codigoEmpresa);
      telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
    }

    function estruturaAtualizada(codigo: number) {
      state.codigoEstrutura = codigo;
    }

    function incluirEstrutura() {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = 0;
      state.operacaoLancamento.empresaSelecionada = state.codigoEmpresa;
      state.exibirEstrutura = true;
    }

    function editarEstrutura() {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Visualizar;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = state.codigoEstrutura;
      state.operacaoLancamento.empresaSelecionada = state.codigoEmpresa;
      state.exibirEstrutura = true;
    }

    async function excluirEstrutura() {
      Modal.confirm({
        title: 'Você deseja remover a estrutura atual?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoRelatorioDRE.excluirEstrutura(state.codigoEstrutura, state.codigoEmpresa);
          if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
            state.codigoEstrutura = 0;
          } else {
            apresentarMensagemErro(retorno.mensagem);
          }
        },
      });
    }

    function incluirGrupo() {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = 0;
      state.operacaoLancamento.empresaSelecionada = state.codigoEmpresa;
      state.exibirGrupo = true;
    }

    function editarGrupo(codigo: number) {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Visualizar;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = codigo;
      state.operacaoLancamento.empresaSelecionada = state.codigoEmpresa;
      state.exibirGrupo = true;
    }

    async function obterGrupos() {
      state.estrutura.grupos = await servicoRelatorioDRE.obterGrupos(state.codigoEstrutura, state.codigoEmpresa);
    }

    async function obterEstrutura() {
      state.estrutura = await servicoRelatorioDRE.obterEstrutura(state.codigoEstrutura, state.codigoEmpresa);
    }

    function obterDescricaoPeriodo(periodo: number) {
      switch (state.tipoPeriodo) {
        case ESazonalidade.mensal:
          return UtilitarioData.obterNomeMes(periodo);
        case ESazonalidade.trimestral:
          return `${periodo}º Trimestre`;
        case ESazonalidade.semestral:
          return `${periodo}º Semestre`;
        default:
          return String(periodo);
      }
    }

    async function obterResultado() {
      const parametros = {} as IParametrosDRE;
      parametros.codigoEstrutura = state.codigoEstrutura;
      parametros.codigoEmpresa = state.codigoEmpresa;
      parametros.tipoPeriodo = state.tipoPeriodo;
      parametros.periodos = state.periodosSelecionados;
      parametros.ano = state.ano;
      parametros.contas = state.contas;
      parametros.centrosCusto = state.centrosCusto;
      state.colunasGrade = [] as IColumn[];

      state.colunasGrade.push({
        title: '', dataIndex: 'descricao', key: 'Descricao', position: 0, visible: true, ellipsis: true, width: 250,
      });

      parametros.periodos.forEach((p) => {
        state.colunasGrade.push({
          title: obterDescricaoPeriodo(p), dataIndex: `valor${p}`, key: `Valor${p}`, position: p, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado,
        });
      });

      const retorno = await servicoRelatorioDRE.obterResultado(parametros);
      if (UtilitarioGeral.validaLista(retorno)) {
        retorno.forEach((r) => {
          const index = state.estrutura.grupos.findIndex((g) => g.codigo === r.codigoGrupo);
          if (index > -1) {
            state.estrutura.grupos[index].resultado = r;
          }
        });
      }
    }

    onBeforeMount(async () => {
      preencherDadosRota();
      await obterPermissoes(ETipoPermissao.Dados);
      await preencherEmpresasDisponiveis();
      await defineEmpresasSelecionadasComPermissao();
      verificaConceitoParaApresentarEmpresas();
      limparTela();
    });

    return {
      state,
      props,
      telaBase,
      ESazonalidade,
      adicionarAtalho,
      removerAtalho,
      estruturaAtualizada,
      atualizarPeriodos,
      atualizarSelecaoEmpresas,
      incluirEstrutura,
      editarEstrutura,
      incluirGrupo,
      editarGrupo,
      excluirEstrutura,
      obterGrupos,
      obterEstrutura,
      obterResultado,
    };
  },
});
